import { format, parse } from 'date-fns';

export const detectBrowserLanguage = () => (navigator.languages && navigator.languages[0]) || navigator.language || "en";

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric"
};

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
};

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  ...dateFormatOptions,
  ...timeFormatOptions
};

export const isDateValidFormat = (date: string) => {
  const regExpDate = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/;
  return regExpDate.exec(date) && date !== "0000-00-00";
}

export const getLocalDateFormat = () => {
  const browserLocale = detectBrowserLanguage();
  // Convert formatted date to date pattern: 2020/03/25 -> yyyy/MM/dd
  const parts = new Intl.DateTimeFormat(browserLocale, dateFormatOptions).formatToParts(new Date());
  return parts.map(part => {
    if (part.type === "year") {
      return "yyyy";
    }
    if (part.type === "month") {
      return "MM";
    }
    if (part.type === "day") {
      return "dd";
    }
    return part.value;
  }).join("");
};

export const localizeDate = (date: Date | null | undefined) => {
  if (date === null || date === undefined) {
    return "";
  }

  //do string conversion somewhere else
  if (typeof date === 'string') {
    return "";
  }

  if (isNaN(date.getTime())) {
    return "";
  }

  return new Intl.DateTimeFormat(detectBrowserLanguage(), dateFormatOptions).format(date);
}

export const localizeTime = (date: Date | null | undefined) => {
  if (date === null || date === undefined || isNaN(date.getTime())) {
    return '';
  }

  return new Intl.DateTimeFormat(detectBrowserLanguage(), timeFormatOptions).format(date);
};

export const localizeDateTime = (date: Date | null | undefined) => {
  if (date === null || date === undefined || isNaN(date.getTime())) {
    return '';
  }

  return new Intl.DateTimeFormat(detectBrowserLanguage(), dateTimeFormatOptions).format(date);
};

// Convert localized date string (e.g. 2020/03/25) to SQL timestamp format yyyy-mm-dd hh:mm:ss
export const convertLocalDateToSqlTimestamp = (date: string) => {
  return format(parse(date, getLocalDateFormat(), new Date()), 'yyyy-MM-dd HH:mm:ss');
};

export const getDateFromStringFormatYYYYDDMM = (date: string | null) => {

  if (!date) {
    return null;
  }

  const year = parseInt(date.slice(0, 4));
  const month = parseInt(date.slice(4, 6)) - 1;
  const day = parseInt(date.slice(6, 8));

  //check for valid numbers afeter parsing
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return null;
  }

  return new Date(year, month, day);
}

