import {isTextEqual, IText} from './types';
import {ELocales} from './ELocales';


export function textToJSON(text: IText[], locale: ELocales) {
  const msgObj: { [key: string]: string } = {};
  text.forEach((t: IText) => {
    if (t.locale === locale) {
      msgObj[t.id] = t.message;
    }
  });
  // fill missing texts with english
  if (locale !== ELocales.EN) {
    text.forEach((t: IText) => {
      if (t.locale === ELocales.EN) {
        if (!msgObj[t.id]) {
          msgObj[t.id] = t.message;
        }
      }
    });
  }
  return msgObj;
}

export function JSONToText(jsonFile: object, locale: ELocales): IText[] {
  const textList: IText[] = [];
  Object.entries(jsonFile).forEach((entry: [string, any]) => {
    textList.push({id: entry[0], locale, message: entry[1]});
  });
  return textList;
}

export function evaluateText(messages: any): boolean {
  try {
    const checkedText: IText[] = messages;
    return !checkedText?.length || isText(checkedText[0]);
  } catch (error) {
    return false;
  }
}

export function isText(text: IText): text is IText {
  if (text.id && text.locale && text.message) {
    return true;
  }
  return false;
}

export const mergeTexts = (currentTexts: IText[], newTexts: IText[]) => {
  const notInCurrent = newTexts.filter(
    newText => currentTexts.findIndex(currentText => isTextEqual(newText, currentText)) < 0
  );
  return currentTexts.map(currentText => {
    const newText = newTexts.find(newText => isTextEqual(currentText, newText));
    return newText || currentText;
  }).concat(...notInCurrent);
};


