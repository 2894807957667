import { useOutletContext } from 'react-router-dom';
import { IntlButton, useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import {
  calculateDescriptionForRule,
  calculateDescriptionForRuleList,
  emptyBusinessRuleWithJSXElementCriteriaGroups,
  emptyBusinessRuleWithStringCriteriaGroups,
  updateWatchValues
} from './ruleUtils';
import { useEffect, useState } from 'react';
import { EntityInformation, Popup, TabBar } from '@sivis/shared/components/entityView';
import styles from './rule.module.scss';
import { ContextType } from './ruleView';
import { RuleAffectedIdentitiesTab } from './ruleAffectedIdentitiesTab';
import { RuleEditCreatePopupInformationTab } from './ruleEditCreatePopupInformationTab';
import { Spinner } from '@sivis/app-shell';
import { BusinessRule, RuleStatus } from '@sivis/identity/api';
import DraftStatusBar from '../components/draft/draftStatusBar';
import DraftCard from '../components/draft/draftCard';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { ActionPopup } from '../components/popup/actionPopup';
import { Alert } from '@mui/material';

const configsGeneral = [
  {
    property: 'name',
    required: true
  },
  {
    property: 'status',
    disabled: true
  },
  {
    property: 'targetValue',
    required: true
  },
  {
    property: 'criteriaGroups',
    disabled: true
  }
];

export const RuleInformationTab = () => {
  const {
    editMode,
    error,
    isLoading,
    isNew,
    businessRule,
    setBusinessRule,
    onSave,
    onPublish,
    onCancel,
    watchValues,
    setWatchValues,
    draftData,
    setDraftData,
    onDeleteDraft
  } = useOutletContext<ContextType>();
  const intl = useCustomIntl();
  const [openEditCriteriaPopup, setOpenEditCriteriaPopup] = useState(false);
  const [publishPopupOpen, setPublishPopupOpen] = useState(false);
  const [flattenedBusinessRule, setFlattenedBusinessRule] = useState(editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups);
  const [flattenedDraft, setFlattenedDraft] = useState(editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups);
  const [temporaryRule, setTemporaryRule] = useState<BusinessRule | undefined>(undefined);
  const translatedConfigs = useTranslateConfigs(configsGeneral, 'fusion.rule.propertyName');
  const primaryTitleRuleCriteriaPopup = isNew ? intl.format('fusion.rule.createRule.title') : intl.format('fusion.rule.editRule.title', { rule: businessRule?.name });
  const secondaryTitleRuleCriteriaPopup = isNew ? intl.format('fusion.rule.createRule.secondaryTitle') : intl.format('fusion.rule.editRule.secondaryTitle', { rule: businessRule?.name });

  const toggleOpenEditCriteriaPopupMode = () => {
    setOpenEditCriteriaPopup(!openEditCriteriaPopup);
  };

  const handleOnSaveDraft = () => {
    let updated: BusinessRule;

    if (isNew || !draftData) {
      // create new draft
      updated = {
        ...temporaryRule ?? businessRule,
        ...watchValues['mainForm'],
        criteriaGroups: temporaryRule?.criteriaGroups ?? businessRule.criteriaGroups,
        isPublished: false
      };
      if (isNew) {
        setBusinessRule(updated);
      } else {
        // reset temporary rule to get unchanged version after creating new draft
        setTemporaryRule(undefined);
      }
    } else {
      // update existing draft
      updated = {
        ...draftData,
        ...watchValues['mainForm'],
        criteriaGroups: draftData.criteriaGroups,
        isPublished: false
      };
    }
    setDraftData(updated);
    onSave(updated);
  };

  const handleOnPublish = () => {
    setPublishPopupOpen(true);
  };

  // TODO: display businessRule instead of draft after saving draft
  const handleOnConfirmPublish = () => {
    let updatedBusinessRule: BusinessRule;
    if (!draftData) {
      if (isNew) {
        // create new published rule
        updatedBusinessRule = {
          ...temporaryRule ?? businessRule,
          ...watchValues['mainForm'],
          criteriaGroups: temporaryRule?.criteriaGroups ?? businessRule.criteriaGroups,
          isPublished: true,
          publishedId: businessRule.id
        };
      } else {
        // update existing published rule
        updatedBusinessRule = {
          ...temporaryRule ?? businessRule,
          ...watchValues['mainForm'],
          criteriaGroups: temporaryRule?.criteriaGroups ?? businessRule.criteriaGroups,
          isPublished: true,
          publishedId: temporaryRule?.publishedId ?? businessRule.publishedId
        };
      }
    } else {
      // publish draft changes to existing rule
      updatedBusinessRule = {
        ...draftData,
        ...watchValues['mainForm'],
        criteriaGroups: draftData.criteriaGroups,
        isPublished: true,
        publishedId: businessRule.publishedId
      };
    }
    setBusinessRule(updatedBusinessRule);
    setTemporaryRule(undefined);
    onPublish(updatedBusinessRule);
    setPublishPopupOpen(false);
  };

  useEffect(() => {
    let updatedRule;
    if (temporaryRule && editMode) {
      updatedRule = {
        ...temporaryRule,
        criteriaGroups: calculateDescriptionForRuleList((temporaryRule.criteriaGroups ?? []), intl.format, temporaryRule.operatorOutsideGroup).toString()
      };
    } else {
      updatedRule = businessRule ? {
        ...businessRule,
        criteriaGroups: editMode
          ? calculateDescriptionForRuleList(((temporaryRule?.criteriaGroups ?? businessRule.criteriaGroups) ?? []), intl.format, businessRule.operatorOutsideGroup).toString()
          : calculateDescriptionForRule((businessRule.criteriaGroups ?? []), intl.format, businessRule.operatorOutsideGroup)
      } : editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups;
    }
    setFlattenedBusinessRule(updatedRule);
  }, [businessRule, editMode, temporaryRule]);

  useEffect(() => {
    if (draftData) {
      const updatedDraft = {
        ...draftData,
        criteriaGroups: calculateDescriptionForRuleList((draftData.criteriaGroups ?? []), intl.format, draftData.operatorOutsideGroup).toString()
      };
      setFlattenedDraft(updatedDraft);
    }
  }, [draftData]);

  const rulePopupTabs = [
    {
      label: intl.format('fusion.rule.ruleCriteria'),
      content: <RuleEditCreatePopupInformationTab
        togglePopupOpen={toggleOpenEditCriteriaPopupMode}
        setTemporaryRule={setTemporaryRule}
      />
    },
    {
      label: intl.format('fusion.rule.affectedIdentities.list.name'),
      content: <RuleAffectedIdentitiesTab />
    }
  ];

  const ruleCriteriaPopup = <Popup
    open={openEditCriteriaPopup}
    onClose={() => setOpenEditCriteriaPopup(false)}
    primaryTitle={primaryTitleRuleCriteriaPopup}
    secondaryTitle={secondaryTitleRuleCriteriaPopup}
    styleType="edit"
    hideButtons
  >
    <TabBar tabs={rulePopupTabs} />
  </Popup>;

  const publishAlert = (
    <Alert severity="info">
      {intl.format(
        'fusion.rule.publishPopup.infoBox'
      )}
    </Alert>
  );
  const publishPopup = (
    <ActionPopup
      open={publishPopupOpen}
      primaryTitle={intl.format(
        'fusion.rule.publishPopup.title'
      )}
      secondaryTitle={intl.format(
        'fusion.rule.publishPopup.secondaryTitle'
      )}
      onClose={() => setPublishPopupOpen(false)}
      onConfirm={handleOnConfirmPublish}
      alert={publishAlert}
    ></ActionPopup>
  );

  const isDraftPublished = draftData ? !draftData.isPublished : !businessRule.isPublished;

  const entityDetailsGeneral = <>
    <DraftStatusBar
      isActive={businessRule.status === RuleStatus.Active}
      draftData={isNew ? true : isDraftPublished}
      additionalInformationLabel={'fusion.rule.propertyName.changes'}
      additionalInformationText={businessRule.changes ?? 'Not active'}
    />
    {editMode && draftData?.publishedId != null &&
      <DraftCard
        draftData={{
          updatedAt: draftData.meta?.updatedAt ?? Date.now().toString(),
          updatedBy: draftData.meta?.updatedBy ?? Date.now().toString()
        }}
        onDiscardDraft={() => onDeleteDraft(draftData.id)}
      />
    }
    <EntityInformation
      key={JSON.stringify(flattenedBusinessRule)}
      editMode={editMode}
      configs={translatedConfigs}
      value={editMode && draftData ? flattenedDraft : flattenedBusinessRule}
      onSubmit={handleOnPublish}
      onSaveDraft={handleOnSaveDraft}
      onCancel={onCancel}
      onWatch={(watchValues) => {
        updateWatchValues('mainForm', watchValues, setWatchValues);
      }}
      displayInTwoColumns={false}
      isDraftable={true}
    />
  </>;

  // TODO: fix layout of button and criteria
  const editCriteriaButton = <IntlButton
    variant="outlined"
    onClick={toggleOpenEditCriteriaPopupMode}
    disabled={!editMode}
    intlId="fusion.rule.editCriteria"
  />;

  const content = isLoading ? <Spinner /> : entityDetailsGeneral;

  return <div className={styles.listContainer}>
    {error ?
      <div>{getTranslatedErrorMessage(error, 'An error occurred', intl.format)}</div>
      : content}
    {editMode && editCriteriaButton}
    {openEditCriteriaPopup && ruleCriteriaPopup}
    {publishPopupOpen && publishPopup}
  </div>;
};
