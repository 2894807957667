import { newId } from '../../common/newId';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BusinessRule,
  RuleStatus,
  useCreateBusinessRuleMutation,
  useDeleteBusinessRuleMutation,
  useGetBusinessRuleQuery,
  useGetDraftForBusinessRuleQuery,
  useUpdateBusinessRuleMutation
} from '@sivis/identity/api';
import { useEffect, useState } from 'react';
import { businessRuleInputFactory, emptyBusinessRule, mapCriteriaGroups } from '../ruleUtils';
import { EntityType, useSingleEditMode } from '../../components/breadcrumb/editModeContext';

export const useBusinessRuleApi = () => {
  const { id = newId } = useParams<{
    id: string
  }>();
  const isNew = id === newId;
  const { data, error, isLoading, refetch } = useGetBusinessRuleQuery({ id }, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const {
    data: draftDataRaw,
    error: draftError,
    refetch: refetchDraft
  } = useGetDraftForBusinessRuleQuery({ id }, {
    skip: isNew,
    refetchOnMountOrArgChange: true
  });
  const navigate = useNavigate();
  const [deleteBusinessRule] = useDeleteBusinessRuleMutation();
  const [updateBusinessRule] = useUpdateBusinessRuleMutation();
  const [createBusinessRule] = useCreateBusinessRuleMutation();
  const [businessRule, setBusinessRule] = useState<BusinessRule>(emptyBusinessRule);
  const [draftData, setDraftData] = useState<BusinessRule | undefined>(undefined);
  const { editMode, toggleEditMode } = useSingleEditMode(EntityType.CUSTOM_RULE, isNew);
  const setId = (id: string) => {
    navigate(`/rule/${id}/information`);
    refetch();
    refetchDraft();
  };

  if (error) {
    console.error(error);
  }
  if (draftError) {
    console.error(draftError);
  }

  useEffect(() => {
    if (data?.businessRule) {
      const fetchedBusinessRule = data.businessRule;
      const updatedRule: BusinessRule = {
        ...fetchedBusinessRule,
        id: id,
        criteriaGroups: mapCriteriaGroups(fetchedBusinessRule.criteriaGroups ?? [], fetchedBusinessRule.operatorOutsideGroup)
      };
      setBusinessRule(updatedRule);
    }
  }, [data?.businessRule]);

  useEffect(() => {
    if (draftDataRaw?.getDraftForBusinessRule) {
      const fetchedDraft = draftDataRaw.getDraftForBusinessRule;
      const updatedDraft: BusinessRule = {
        ...fetchedDraft,
        id: fetchedDraft.id,
        criteriaGroups: mapCriteriaGroups(fetchedDraft.criteriaGroups ?? [], fetchedDraft.operatorOutsideGroup)
      };
      setDraftData(updatedDraft);
    }
  }, [draftDataRaw?.getDraftForBusinessRule]);

  const goBack = () => navigate('/rule');

  const onDelete = () => {
    deleteBusinessRule({ id }).then(goBack);
  };

  const onDeleteDraft = (draftId: string) => {
    deleteBusinessRule({ id: draftId }).then(() => {
      refetch();
      refetchDraft();
      setDraftData(undefined);
      toggleEditMode();
    });
  };

  const goBackIfNewElseToggleEditModeAndRefetch = () => {
    if (isNew) {
      goBack();
    } else {
      toggleEditMode();
      refetch();
      refetchDraft();
    }
  };

  const resetDraftDataAndToggleEditMode = () => {
    setDraftData(undefined);
    toggleEditMode();
  };

  let onSaveDraft;
  let onPublish;
  let onCancel;

  onSaveDraft = (value: BusinessRule) => {
    value.publishedId = isNew ? null : businessRule.publishedId;
    if (isNew || !draftData) {
      // create new draft
      createBusinessRule({ input: businessRuleInputFactory(value) }).then(() => {
        goBackIfNewElseToggleEditModeAndRefetch();
      });
    } else {
      // update existing draft
      updateBusinessRule({
        id: draftData?.id,
        input: businessRuleInputFactory(value)
      }).then(() => {
        goBackIfNewElseToggleEditModeAndRefetch();
      });
    }
  };

  onPublish = (value: BusinessRule) => {
    if (!draftData) {
      if (isNew) {
        // create new published rule
        createBusinessRule({ input: businessRuleInputFactory(value) }).then(() => {
          goBack();
          resetDraftDataAndToggleEditMode();
        });
      } else {
        // update existing published rule
        updateBusinessRule({ id: businessRule.id, input: businessRuleInputFactory(value) }).then(() => {
          refetch();
          resetDraftDataAndToggleEditMode();
        });
      }
    } else {
      // publish draft changes to existing rule
      updateBusinessRule({ id: draftData.id, input: businessRuleInputFactory(value) }).then(() => {
        setId(draftData?.publishedId ?? draftData?.id ?? id);
        resetDraftDataAndToggleEditMode();
      });
    }
  };

  onCancel = () => {
    goBackIfNewElseToggleEditModeAndRefetch();
  };

  let onActivate = () => {
    if (businessRule?.id) {
      const activatedRule = { ...businessRule, status: RuleStatus.Active };
      updateBusinessRule({ id: businessRule.id, input: businessRuleInputFactory(activatedRule) })
      .then(() => {
        refetch();
      });
    }
  };
  let onDeactivate = () => {
    if (businessRule?.id) {
      const deactivatedRule = { ...businessRule, status: RuleStatus.Inactive };
      updateBusinessRule({ id: businessRule.id, input: businessRuleInputFactory(deactivatedRule) })
      .then(() => {
        refetch();
      });
    }

  };

  return {
    id,
    businessRule,
    setBusinessRule,
    error,
    isNew,
    onSave: onSaveDraft,
    onPublish,
    onDelete,
    onDeleteDraft,
    onCancel,
    isLoading,
    editMode,
    toggleEditMode,
    onActivate,
    onDeactivate,
    draftData,
    setDraftData,
    refetch
  };
};
