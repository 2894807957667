import { CustomIntlType } from '@sivis/intl';
import {
  BusinessRule,
  BusinessRuleInput,
  CriteriaGroup,
  CriteriaGroupInput,
  Criterion,
  CriterionInput,
  RuleAction,
  RuleLogicalOperator,
  RuleStatus
} from '@sivis/identity/api';
import { isEmpty } from 'lodash';

export type CreatableCriteriaGroup = CriteriaGroup & { isNew?: boolean };
export type CreatableCriterion = Criterion & { isNew?: boolean };

// Helper to get operatorInsideGroup formatted
export const getOperatorInsideGroupFormatted = (operatorOutsideGroup: RuleLogicalOperator, formatFn: CustomIntlType['format']) =>
  operatorOutsideGroup === RuleLogicalOperator.And
    ? formatFn('fusion.rule.logicalOperator.or')
    : formatFn('fusion.rule.logicalOperator.and');

// Helper to get operatorOutsideGroup formatted
export const getOperatorOutsideGroupFormatted = (operatorOutsideGroup: RuleLogicalOperator, formatFn: CustomIntlType['format']) =>
  operatorOutsideGroup === RuleLogicalOperator.And
    ? formatFn('fusion.rule.logicalOperator.and')
    : formatFn('fusion.rule.logicalOperator.or');

// Helper to get operator formatted bold
export const getOperatorTextBold = (operator: RuleLogicalOperator, formatBoldFn: CustomIntlType['formatBold']) =>
  operator === RuleLogicalOperator.And
    ? formatBoldFn('fusion.rule.logicalOperator.andBold')
    : formatBoldFn('fusion.rule.logicalOperator.orBold');

// Helper to convert a rule operation
const operationToSymbol = (negate: boolean, formatFn: CustomIntlType['format']): string => {
  // for now, we only support equals
  const translationKey = negate ? 'fusion.rule.ruleOperation.notEqualsSign' : 'fusion.rule.ruleOperation.equalsSign';
  return formatFn(translationKey);
};

// Helper to format a single criterion
const formatCriterion = (
  criterion: Criterion,
  formatFn: CustomIntlType['format']
): string => {
  const operationSymbol = operationToSymbol(criterion.negate ?? false, formatFn);
  return `${criterion.entityField} ${operationSymbol} ${criterion.value}`;
};

// Helper to format a group for strings
const formatGroup = (
  group: CriteriaGroup,
  operatorOutsideGroup: RuleLogicalOperator,
  formatFn: CustomIntlType['format']
): string => {
  const operatorInsideGroupFormatted = getOperatorInsideGroupFormatted(operatorOutsideGroup, formatFn);

  return group.criteriaInsideGroup.map(criterion => formatCriterion(criterion, formatFn)).join(` ${operatorInsideGroupFormatted} `);
};

// Function to format a group for JSX
const formatGroupForJSX = (
  group: CriteriaGroup,
  operatorOutsideGroup: RuleLogicalOperator,
  formatFn: CustomIntlType['format']
): JSX.Element => {
  const operatorInsideGroupFormatted = getOperatorInsideGroupFormatted(operatorOutsideGroup, formatFn);

  const formattedCriteria = group.criteriaInsideGroup.reduce<JSX.Element[]>((prev, curr) => {
    const formattedCriterion = <span key={curr.id}>{formatCriterion(curr, formatFn)}</span>;
    if (prev.length > 0) {
      return [...prev, <strong
        key={`${curr.id}-operator`}>{operatorInsideGroupFormatted}</strong>, formattedCriterion];
    }
    return [formattedCriterion];
  }, []);

  return <div>{formattedCriteria}</div>;
};


export const calculateDescriptionForRule = (
  criteriaGroups: CriteriaGroup[],
  formatFn: CustomIntlType['format'],
  operatorOutsideGroup: RuleLogicalOperator
): JSX.Element => {

  return (
    <span>
      {criteriaGroups.map((group, index) => (
        <div key={group.id}>
          {formatGroupForJSX(group, operatorOutsideGroup, formatFn)}
          {index < criteriaGroups.length - 1 && (
            <strong>{formatFn(`fusion.rule.logicalOperator.${operatorOutsideGroup.toLowerCase()}`)}</strong>
          )}
        </div>
      ))}
    </span>
  );
};

export const calculateDescriptionForRuleList = (
  criteriaGroups: CriteriaGroup[],
  formatFn: CustomIntlType['format'],
  operatorOutsideGroup: RuleLogicalOperator
): string => {
  if (!criteriaGroups || !Array.isArray(criteriaGroups) || isEmpty(criteriaGroups)) {
    return '';
  }

  const formattedGroups = criteriaGroups.map(group => formatGroup(group, operatorOutsideGroup, formatFn));
  const operatorOutsideGroupFormatted = getOperatorOutsideGroupFormatted(operatorOutsideGroup, formatFn);

  if (criteriaGroups.length > 1) {
    return formattedGroups.map(group => `(${group})`).join(` ${operatorOutsideGroupFormatted} `);
  } else {
    return formattedGroups.join(` ${operatorOutsideGroupFormatted} `);
  }
};


export const mapToEnum = <T extends object>(enumType: T, value: string | undefined): T[keyof T] => {
  if (!value) {
    throw new Error('Value is undefined');
  }
  const enumValues = Object.values(enumType) as string[];
  if (!enumValues.includes(value)) {
    throw new Error(`Invalid enum value "${value}"`);
  }
  return value as T[keyof T];
};

export const updateWatchValues = (
  key: string,
  values: any,
  setWatchValues: (value: ((prevState: WatchValues) => WatchValues) | WatchValues) => void
) => {
  setWatchValues((prev: WatchValues) => ({
    ...prev,
    [key]: values
  }));
};

export interface WatchValues {
  mainForm?: Partial<BusinessRule>;

  [key: string]: any;
}

export const emptyBusinessRule = (): BusinessRule => ({
  id: '',
  name: '',
  targetValue: '',
  ruleAction: RuleAction.AssignSivisJob,
  status: RuleStatus.Inactive,
  criteriaGroups: [],
  operatorOutsideGroup: RuleLogicalOperator.And
});

interface EmptyBusinessRuleWithoutCriteriaGroups {
  id: string;
  name: string;
  targetValue: string;
  ruleAction: string;
  status: string;
  operatorOutsideGroup: string;
  criteriaGroups: string | JSX.Element;
}

const emptyBusinessRuleWithoutCriteriaGroups = {
  id: '',
  name: '',
  targetValue: '',
  ruleAction: RuleAction.AssignSivisJob,
  status: RuleStatus.Inactive,
  operatorOutsideGroup: RuleLogicalOperator.And
};

export const emptyBusinessRuleWithStringCriteriaGroups: EmptyBusinessRuleWithoutCriteriaGroups = {
  ...emptyBusinessRuleWithoutCriteriaGroups,
  criteriaGroups: ''
};

export const emptyBusinessRuleWithJSXElementCriteriaGroups: EmptyBusinessRuleWithoutCriteriaGroups = {
  ...emptyBusinessRuleWithoutCriteriaGroups,
  criteriaGroups: <div></div>
};

const emptyBusinessRuleInput = (): BusinessRuleInput => ({
  name: '',
  targetValue: '',
  ruleAction: RuleAction.AssignSivisJob,
  status: RuleStatus.Inactive,
  criteriaGroups: [],
  operatorOutsideGroup: RuleLogicalOperator.And
});

export const businessRuleInputFactory = (businessRule?: BusinessRule | null): BusinessRuleInput => {
  if (!businessRule) {
    return emptyBusinessRuleInput();
  }

  // if the criteriaGroup.id is an empty string or includes "new" we don't want to include criteriaGroupId in the input (group does not exist yet)
  // if the criteriaGroup.id is not an empty string or and does not include "new", we want it in the input as criteriaGroupId (group does exist and needs to be updated by the id)
  // same for criterion.id
  const criteriaGroupsInput: CriteriaGroupInput[] = (businessRule.criteriaGroups && businessRule.criteriaGroups?.length > 0) ?
    businessRule.criteriaGroups.map((criteriaGroup: CreatableCriteriaGroup) => {
      const criteriaGroupInput: CriteriaGroupInput = {
        criteriaInsideGroup: criteriaGroup.criteriaInsideGroup.map((criterion: CreatableCriterion) => {
          const criterionInput: CriterionInput = {
            entityField: criterion.entityField,
            operation: criterion.operation,
            negate: criterion.negate ?? false,
            value: criterion.value
          };
          if (criterion.id !== '' && criterion.isNew !== true) {
            criterionInput.criterionId = criterion.id;
          }
          return criterionInput;
        })
      };
      if (criteriaGroup.id !== '' && criteriaGroup.isNew !== true) {
        criteriaGroupInput.criteriaGroupId = criteriaGroup.id;
      }
      return criteriaGroupInput;
    }) : [];

  return {
    criteriaGroups: criteriaGroupsInput,
    name: businessRule.name,
    operatorOutsideGroup: businessRule.operatorOutsideGroup,
    ruleAction: businessRule.ruleAction,
    status: businessRule.status,
    targetValue: businessRule.targetValue,
    changes: businessRule.changes ?? '',
    isPublished: businessRule.isPublished,
    publishedId: businessRule.publishedId
  };
};

export const mapCriteriaGroups = (criteriaGroups: CriteriaGroup[] | undefined, operatorOutsideGroup: RuleLogicalOperator) => {
  if (!criteriaGroups || isEmpty(criteriaGroups)) {
    return [];
  } else {
    const operatorInsideGroup = operatorOutsideGroup === RuleLogicalOperator.And
      ? RuleLogicalOperator.Or
      : RuleLogicalOperator.And;
    return criteriaGroups.map((criteriaGroup) => {
      return {
        ...criteriaGroup,
        operatorInsideGroup: operatorInsideGroup
      };
    });
  }
};
