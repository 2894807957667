import { PreparedRegistration, prepareRegisterComponent } from '@sivis/shared/components/registry';
import { HEADER_ITEM_LEFT, HEADER_ITEM_RIGHT } from '@sivis/app-shell';
import { SearchField } from '@sivis/shared/components/entityView';
import { useDispatch, useSelector } from 'react-redux';
import {
  ELocales,
  ELocaleText,
  selectAvailableLanguages,
  selectLanguage,
  setLanguage
} from '@sivis/intl';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import styles from './headerItems.module.scss';

const AppName = ({name}: { name: string }) => {
  return <div className={styles["appName"]}>{name}</div>;
};

export const prepareHeaderAppNameRegistration = (name: string): PreparedRegistration =>
  prepareRegisterComponent({
  id: `${HEADER_ITEM_LEFT}/${AppName.name}`,
  name: "appName",
    component: () => <AppName name={name}/>,
  type: HEADER_ITEM_LEFT,
  order: 0
});

const HeaderSearchField = () => {
  const onSearch = () => {
    //TODO
  };
  return <div className={styles["searchFieldContainer"]}>
    <SearchField placeholder="Search..." onSearch={onSearch}/>
  </div>;
};

const HeaderLanguageSwitcher = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const availableLanguages = useSelector(selectAvailableLanguages);

  const handleChange = (event: SelectChangeEvent<ELocales | string>) => {
    dispatch(setLanguage(event.target.value));
  }

  // TODO: modify style when UI decision has been made
  // currently available languages are en & de, needs to be extended if others are needed
  return (
    <Select value={language} onChange={handleChange} variant="outlined" size="small">
      {availableLanguages.map(lang =>
        <MenuItem key={lang} value={lang}>{ELocaleText[lang]}</MenuItem>
      )}
    </Select>
  );
}

export const headerLanguageSwitcherRegistration: PreparedRegistration = prepareRegisterComponent({
  id: `${HEADER_ITEM_RIGHT}/${HeaderLanguageSwitcher.name}`,
  name: "headerLanguageSwitcher",
  component: HeaderLanguageSwitcher,
  type: HEADER_ITEM_RIGHT,
  order: 0
});
