import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import styles from './AppShell.module.scss';
import { Spinner } from './components/spinner/Spinner';
import { MountTester, useMountTester } from './components/utils/MountTester';
import BreadcrumbBar from './components/breadcrumbBar/BreadcrumbBar';
import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectIntlMessages, selectLanguage } from '@sivis/intl';
import { selectLoadingState, setInitializing } from './redux/appSlice';
import { AppLoadingState } from './redux/AppLoadingState';
import { selectTheme } from '@sivis/shared/theme';

interface AppProps {
  children: React.ReactNode;
  initialize: () => void;
  sidebarItems: React.ReactNode;
  dialogItems?: React.ReactNode;
  breadcrumbBarItems?: React.ReactNode;
  showContentOnly?: boolean;
  alwaysShowSidebar?: boolean;
}

export const AppShell = (props: AppProps) => {
  const language = useSelector(selectLanguage);
  const messages = useSelector(selectIntlMessages);
  const dispatch = useDispatch();

  const loadingState = useSelector(selectLoadingState);

  const muiTheme = useSelector(selectTheme);

  useEffect(() => {
    if (loadingState === AppLoadingState.starting) {
      dispatch(setInitializing());
      props.initialize();
    }
  }, []);


  // Check if user is using tab
  useEffect(() => {
    const handleFirstTab = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        document.body.classList.add("user-is-tabbing");
        window.removeEventListener("keydown", handleFirstTab);
      }
    };

    window.addEventListener("keydown", handleFirstTab);
    return () => window.removeEventListener("keydown", handleFirstTab);
  }, []);

  useMountTester({label: "App.tsx"});

  if (loadingState !== AppLoadingState.ready) {
    return <Spinner positionAbsolute={true}/>;
  }

  return (
    <StyledEngineProvider injectFirst>
      <MountTester label="AfterStyledEngineProvider"/>
      <ThemeProvider theme={muiTheme}>
        <MountTester label="AfterThemeProvider"/>
        <IntlProvider
          locale={language}
          messages={messages}
        >
          <MountTester label="AfterIntlProvider"/>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MountTester label="AfterLocalizationProvider"/>
            <div id={styles.evoraptorMain}>
              {props.showContentOnly ?
                props.children :
                <>
                  <MountTester label="AfterShowContent"/>
                  <Header alwaysShowSidebar={props.alwaysShowSidebar}/>
                  <div id={styles.evoContent}>
                    <Sidebar>
                      {props.sidebarItems}
                    </Sidebar>
                    <div id={styles.evoContentRightSide}>
                      {props.breadcrumbBarItems ? <BreadcrumbBar>
                        {props.breadcrumbBarItems}
                      </BreadcrumbBar> : null}
                      <div id={styles.evoPageContent} role="main">
                        {props.children}
                      </div>
                    </div>
                  </div>
                  <div id={styles.evoFooter}>{/* TODO: FOOTER */}</div>
                </>
              }
              {props.dialogItems}
            </div>
          </LocalizationProvider>
        </IntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
