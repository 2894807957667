import { PreparedRegistration } from '@sivis/shared/components/registry';
import { LoggingService } from '@sivis/shared/utils';
import {
  headerLanguageSwitcherRegistration,
  prepareHeaderAppNameRegistration
} from '@sivis/shared/components/header-items';
import { logoutButtonRegistration } from './components/login/logoutButton';

const headerAppNameRegistration = prepareHeaderAppNameRegistration("Admin");

const PREPARED_REGISTRATIONS: PreparedRegistration[] = [
  headerAppNameRegistration,
  headerLanguageSwitcherRegistration,
  logoutButtonRegistration
];

export const registerComponents = () => {
  LoggingService.trace("Registering components", "registerComponents");
  PREPARED_REGISTRATIONS.forEach(registerer => {
    const entry = registerer();
    if (entry) {
      LoggingService.trace("Component registered: " + entry.id, "registerComponents");
    }
  });
}
