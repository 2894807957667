import {
  BusinessRule,
  FilterExpression,
  GeneratedBusinessRuleTypes,
  searchBusinessRulesFilter,
  useGetBusinessRulesQuery
} from '@sivis/identity/api';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../../common/parsePageResult';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import { getExistingOrCombinedFilter, useListSearch } from '../../utils/filterUtils';
import { FilterOperator } from '@sivis/shared/api-utils';

export const rulesDraftFilter: FilterExpression = {
  logicalValue: [
    {
      logicalValue:
        [
          {
            booleanValue: false,
            operator: FilterOperator.EQ,
            name: 'isPublished'
          },
          {
            uuidValue: null,
            operator: FilterOperator.ISNULL,
            name: 'publishedId'
          }
        ],
      operator: FilterOperator.AND
    },
    {
      booleanValue: true,
      operator: FilterOperator.EQ,
      name: 'isPublished'
    }
  ],
  operator: FilterOperator.OR
};

export const useBusinessRulesPageApi = (extraFilter?: FilterExpression) => {
  const { filter, onSearch } = useListSearch(searchBusinessRulesFilter);
  // filter out drafts for published rules in the list (only show published rules and drafts that don't have a published rule)
  let rulesFilter = getExistingOrCombinedFilter(filter, extraFilter);
  rulesFilter = getExistingOrCombinedFilter(rulesDraftFilter, rulesFilter);

  const useBusinessRulesPageQuery = (cursor: string | null) =>
    useGetBusinessRulesQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: rulesFilter
    }, { refetchOnMountOrArgChange: false });

  const { isLoading, error } = useBusinessRulesPageQuery(null);

  const parseBusinessRulesPageResult = (res?: GeneratedBusinessRuleTypes.GetBusinessRulesQuery): CursorBasedPageResult<BusinessRule> => {
    return parseCursorBasedPageResult('businessRules', res);
  };

  return {
    pageSize: DEFAULT_PAGE_SIZE,
    onSearch,
    useBusinessRulesPageQuery,
    parseBusinessRulesPageResult,
    isLoading,
    error
  };
};
