import React, { FunctionComponent } from 'react';
import { HasIntlId } from './HasIntlId';
import { useSelector } from 'react-redux';
import { selectMessageTextById } from '../intlSelectors';
import { Button, ButtonProps } from '@mui/material';
import { HasAriaIntlId } from './HasAriaIntlId';


interface IconProps {
  icon?: JSX.Element;
}

export const IntlButton: FunctionComponent<HasIntlId & HasAriaIntlId & ButtonProps & IconProps> =
  ({ intlId, ariaIntlId, icon, ...props }) => {
    const text = useSelector(state => selectMessageTextById(state, intlId));
    const translatedAriaLabel = useSelector(state => selectMessageTextById(state, ariaIntlId ?? props['aria-label']));

    return (
      <Button {...props} aria-label={translatedAriaLabel}>
        {icon} {/* icon rendering */}
        {text}
      </Button>
    );
  };
